import React from "react";

function Introduce(){
    return (
        <>
            <div className="containerWrapper text-center m-t-30">
                <h2>소개 준비 중입니다.</h2>
                <h4>이 페이지는 현재 공사 중입니다.</h4>
            </div>
        </>
    )
}

export default Introduce;