import React from "react";

function BookList({name, thumb}){

    return (
        <>
        <div className="bookLayer col-lg-3 col-md-3">
            <div className="bookImage">
                <img src={thumb} alt={{name}+"도서 이미지"} />
            </div>
            <div className="bookName m-t-5">
                {name}
            </div>
        </div>
        </>
    )

}

export default BookList;