import React from "react";

function PageError404(){
    return (<>
        <div className="container">
            <div className="containerWrapper text-center">

                <div className="m-t-30" style={{fontSize: '12em', letterSpacing:'-0.15em', textShadow: '#999 2px 2px 1px', marginLeft:'-0.3em'}}>
                    <span className="d-inline-block" style={{zIndex: '3'}}>4</span>
                    <span className="d-inline-block" style={{zIndex: '2'}}>0</span>
                    <span className="d-inline-block" style={{zIndex: '1'}}>4</span>
                </div>

                <div className="m-t-30 m-b-30">
                <h2 style={{letterSpacing: '-0.05em'}}>페이지가 없습니다.</h2>
                <h4>페이지가 사라졌거나, 없는 페이지입니다.</h4>
                </div>
            </div>
        </div>
    </>);
}

export default PageError404;