import React from 'react';
import {HashRouter, Route, Link, Switch, BrowserRouter } from "react-router-dom";
import "./resource/default.css";
import "./resource/skel.css";
import "./resource/skel.js";

import Header from "./templates/Header";
import Footer from "./templates/Footer";

import Main from "./routers/Main";
import Introduce from "./routers/Introduce";
import PageError404 from "./routers/pageError/PageError404";

function App() {
  return (
      <HashRouter basename={process.env.PUBLIC_URL}>
          <Header />
          <div id="siteContents">
          <Switch>
              <Route path="/" component={Main} exact={true}></Route>
              <Route path="/introduce" component={Introduce} exact={true}></Route>
              <Route component={PageError404} status={404}></Route>
          </Switch>
          </div>
          <Footer />
      </HashRouter>
  );
}

export default App;
