import React from "react";
import { Link } from "react-router-dom";

class Header extends React.Component{
    state = {
        isTopMenuOpen: false
    };

    TopMenuToggle = () => {
        const {isTopMenuOpen} = this.state;
        if(isTopMenuOpen===false){
            this.setState({ isTopMenuOpen: true });
        }else{
            this.setState({ isTopMenuOpen: false });
        }
    };

    render() {
        const {isTopMenuOpen} = this.state;
        return <>
            <div id="topLinkToMobile" className=""></div>
            {isTopMenuOpen===true ?
            <div id="topLinkMenuModal">
                <div className="background" onClick={this.TopMenuToggle}></div>
                <div className="modalContainer">
                    <div id="mobileTopButtonInner" className="">
                        <button onClick={this.TopMenuToggle}><i className="fas fa-bars"></i></button>
                    </div>
                    <div className="topButtonContents">
                        <a href="https://moe.work" target="_blank">모에워크</a>
                        <a href="https://discord.gg/sPfWmfm" target="_blank">디스코드</a>
                        <Link to="/login">작가 로그인</Link>
                    </div>
                </div>
            </div>
            : ''}
            <div id="mobileTopButton" className="">
                <button onClick={this.TopMenuToggle}><i className="fas fa-bars"></i></button>
            </div>
            <div id="topLink" className="container view-pc" style={{display: 'none'}}>
                <div className="containerWrapper float-box">
                    <div className="topLinkLeft float-left w-p-50">
                        <ul>
                            <li><a href="https://moe.work" target="_blank">모에워크</a></li>
                            <li><a href="https://discord.gg/sPfWmfm" target="_blank">디스코드</a></li>
                        </ul>
                    </div>
                    <div className="topLinkRight float-right w-p-50">문의</div>
                </div>
            </div>
            <div id="header" className="m-t-30 text-center m-b-30">
                <Link to="/"><img src="/logo1x.png" srcSet="/logo2x.png 2x, /logo3x.png 3x" alt="도서출판 모에워크"/></Link>
            </div>
            <div id="siteNavigation" className="container p-t-15 p-b-15">
                <div className="containerWrapper">
                    <ul className="float-box">
                        <li><Link to="/">메인</Link></li>
                        <li><Link to="/Introduce">소개</Link></li>
                    </ul>
                </div>
            </div>
        </>
    }
}

export default Header;