import React from "react";
import BookList from "../components/BookList";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

import Axios from "axios";

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

class Main extends React.Component{
    state = {
        slider: [],
        bookList: []
    };

    updateSlider = () => {
        let slider = [
            {
                key:0,
                url:'/img/banner.png',
                urlMobile:'/img/banner_mobile.png',
                color: '#111',
                description: '책 출간 기념'
            },{
                key:1,
                url:'/img/banner.png',
                urlMobile:'/img/banner_mobile.png',
                color: '#111',
                description: '책 출간 기념'
            }
        ];
       this.setState({slider: slider});
    };

    updateBookList = async () => {
        let url = "https://moebooks.hanbitgaram.workers.dev/";
        const thisInner = this;
        const dataMercine = await Axios.get(url).then(function(response) {
            //console.log(response);

            thisInner.setState({bookList: response.data.data});
        }).catch(function(error){
            console.log(error); // Network Error
            console.log(error.status); // undefined
            console.log(error.code); // undefined

        });
        //console.log(dataMercine.data.data);
    };

    componentDidMount = () => {
        this.updateSlider();
        this.updateBookList();
    };


    render() {
        const { slider, bookList } = this.state;
        const thisInner = this;
        // rebuildonupdate swiper 제거
        return (
            <>
                <div id="mainSlider" className="text-center">
                    { slider.length===0?
                        '슬라이더 로딩 중입니다...':
                        <Swiper
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}
                            navigation
                            loop
                            delay={100}
                            pagination
                            autoplay
                        >
                            {slider.map(result => {
                                return <SwiperSlide key={result.key}>{({isActive}) => (
                                    <div style={{backgroundColor: "" + result.color + ""}}>
                                        <img src={result.url} alt={result.description} className="view-pc"/>
                                        <img src={result.urlMobile} alt={result.description}
                                             className="view-tablet-mobile"/>
                                    </div>
                                )}</SwiperSlide>
                            })}
                        </Swiper>
                    }
                </div>

                <div id="landingPage_1" className="landingElement container p-t-80 p-b-60" style={{backgroundColor: '#eee'}}>
                    <div className="containerWrapper text-center">
                        <h3>도서출판 모에워크</h3>
                        <h4 className="m-t-5">여러분의 이야기를 책으로 만들어드립니다.</h4>
                        <div className="col-box m-t-30">
                            <div className="col-lg-4 col-md-4">
                                <div className="round">
                                    <i className="fas fa-atlas"></i>
                                </div>
                                <div className="title">전자책 전문 제작</div>
                                <div className="description">도서출판 모에워크는<br />전자책 전문 제작 출판사입니다.<br />(종이책 제작도 진행합니다)</div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="round">
                                    <i className="fas fa-upload round1"></i>
                                </div>
                                <div className="title">국내 최다 인터넷 서점 유통</div>
                                <div className="description">리디북스, 알라딘, Yes24 등<br />15여개의 인터넷 서점에<br />일괄적으로 도서를 유통합니다.</div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="round">
                                    <i className="fas fa-won-sign round2"></i>
                                </div>
                                <div className="title">투명한 인세</div>
                                <div className="description">권당 가격과 분배율을<br/>언제든지 볼 수 있습니다.</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="landingPage_2" className="landingElement container p-t-80 p-b-80">
                    <div className="containerWrapper text-center">
                        <h3>출간 문의</h3>
                        <h4 className="m-t-5">도서출판 모에워크 디스코드로 초대합니다.</h4>
                        <div className="m-t-20 discordBtn"><a href="https://discord.gg/sPfWmfm" target="_blank"><i className="fab fa-discord"></i> <span>접속하기</span></a></div>
                        <div className="smallText">하단의 이메일로도 출간문의가 가능합니다.</div>
                    </div>
                </div>

                <div id="landingPage_3" className="landingElement container p-t-80 p-b-60" style={{backgroundColor: '#eee'}}>
                    <div className="containerWrapper text-center">
                        <h3>발간된 책</h3>
                        <h4 className="m-t-5">모에워크에서 출판한 책을 소개합니다.</h4>
                        <div className="col-box m-t-20">
                            { bookList.length===0 ? <div className="bookLayer m-t-50 m-b-50 text-center">책 목록을 가져오는 중입니다.</div> : bookList.map(result => {
                                return <BookList key={result.wr_id} name={result.wr_subject} thumb={result.thumb} />
                            })  }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Main;