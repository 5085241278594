import React from "react";

class Footer extends React.Component{
    state = {
        emailViewing: false,
        phoneViewing: false
    };

    showMoeBooksEmail = () => {
        this.setState({emailViewing: true});
    };
    showMoeBooksPhone = () => {
        this.setState({phoneViewing: true});
    };


    render() {
        const { emailViewing, phoneViewing } = this.state;
        return (<>
            <div id="siteFooter" className="container">
                <div id="siteFooterWrapper" className="containerWrapper p-t-30 p-b-30">
                    <div className="col-box view-pc">
                        <div className="col-3">
                            <img src="/img/logo1x_footer.png"
                                 srcSet="/img/logo2x_footer.png 2x, /img/logo3x_footer.png 3x" alt="푸터 로고"
                                 className="pcFooterImage"/>
                        </div>
                        <div className="col-9">
                            상호 : 도서출판 모에워크 (MOEWORK BOOKS)<br/>
                            사업자등록번호 : 484-93-01397<br/>
                            문의메일 : {emailViewing===false ? <a onClick={this.showMoeBooksEmail} tabIndex="0">이메일 보기</a> : 'books@moe.work'}<br/>
                            대표 전화번호 : {phoneViewing===false ? <a onClick={this.showMoeBooksPhone} tabIndex="0">전화번호 보기</a> : '0506-435-6394'}<br/>
                            상표출원번호 : 40-2020010-6072 <br/>
                            본 사이트는 모에워크 엔터테인먼트 출판팀이 운영하고 있습니다.
                            <br/>
                            <br/>
                            Copyright 2020 도서출판 모에워크. All Rights Reserved.
                        </div>
                    </div>
                    <div className="view-tablet-mobile">
                        <div className="w-330 m-l-auto m-r-auto">
                            <img src="/img/logo1x_footer.png"
                                 srcSet="/img/logo2x_footer.png 2x, /img/logo3x_footer.png 3x" alt="푸터 로고"
                                 className="mobileFooterImage"/>
                        </div>
                        <div className="m-t-20 text-left w-330 m-l-auto m-r-auto">
                            상호 : 도서출판 모에워크 (MOEWORK BOOKS)<br/>
                            사업자등록번호 : 484-93-01397<br/>
                            문의메일 : {emailViewing===false ? <a onClick={this.showMoeBooksEmail} tabIndex="0">이메일 보기</a> : 'books@moe.work'}<br/>
                            대표 전화번호 : {phoneViewing===false ? <a onClick={this.showMoeBooksPhone} tabIndex="0">전화번호 보기</a> : '0506-435-6394'}<br/>
                            상표출원번호 : 40-2020010-6072 <br/>
                            본 사이트는 모에워크 엔터테인먼트 출판팀이 운영하고 있습니다.
                            <br/>
                            <br/>
                            Copyright 2020 도서출판 모에워크. All Rights Reserved.
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
}

export default Footer;